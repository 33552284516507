import { Chip, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import SelectAiModelComponent from "../../SelectAiModelComponent";
import { getPersonalizeAIHref } from "../../../const/Hrefs";
import Image from "next/image";
import CurvedArrow from "../../../../public/curved-arrow.svg";
import React from "react";
import { IUserProduct, ProductSitemapScanStatus } from "../../../types/models/user.types";
import { useRouter } from "next/router";
import LabeledFormRow from "./LabeledFormRow";

interface Props {
  products: Array<{
    product: IUserProduct;
    status: ProductSitemapScanStatus;
  }>;
  productId: string;
  onProductChange: (product: IUserProduct) => void;
  loading?: boolean;
  required?: boolean;
}

const PersonalizedAiInput: React.FC<Props> = ({
  products,
  productId,
  onProductChange,
  required = false,
}) => {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack alignItems="center" width="100%">
      <LabeledFormRow label="Personalize an AI model with your product details" required={required}>
        <SelectAiModelComponent
          products={products}
          productId={productId}
          handleSelectProduct={(userProduct) => {
            onProductChange(userProduct);
          }}
          showPredraftOption
        />
      </LabeledFormRow>
      {!isMobile && (
        <Stack
          spacing={1}
          sx={{
            position: "absolute",
            right: ["-65px", "-65px", "-65px", "-22px"],
            transform: "translateY(-50%) rotate(-5deg)", // added rotation here
          }}
        >
          <Chip
            onClick={() => router.push(getPersonalizeAIHref({ redirect_url: router.asPath }))}
            label={
              <Typography variant="body2" fontFamily="Open Sans Semi Bold">
                <Typography variant="body2" fontFamily="Open Sans Bold" component="span">
                  Personalize
                </Typography>{" "}
                Your Own AI!
              </Typography>
            }
            sx={{
              backgroundColor: "#005885",
              color: "white",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              transition: "transform 0.3s ease-in-out", // smooth transition for the scaling effect
              "&:hover": {
                backgroundColor: "#005885",
                transform: "scale(1.1)", // scale the chip up by 10% on hover
                cursor: "pointer",
              },
            }}
          />
          <Image src={CurvedArrow} height={30} width={30} alt="Arrow" />
        </Stack>
      )}
    </Stack>
  );
};

export default PersonalizedAiInput;
