import React from "react";
import CoreTextField from "../../core/CoreTextField";
import LabeledFormRow from "./LabeledFormRow";

interface Props {
  relatedKeywords: string;
  onRelatedKeywordsChange: (relatedKeywords: string) => void;
  loading?: boolean;
  required?: boolean;
}

const RelatedKeywordsInput: React.FC<Props> = ({
  relatedKeywords,
  onRelatedKeywordsChange,
  loading = false,
  required = false,
}) => {
  return (
    <LabeledFormRow
      label="LSI keywords that support your main target keywords."
      required={required}
    >
      <CoreTextField
        multiline
        onChange={(value) => {
          onRelatedKeywordsChange(value);
        }}
        value={relatedKeywords}
        label="Related Keywords (LSI)"
        placeholder="primate diet, tropical fruits"
        loading={loading}
        required={required}
      />
    </LabeledFormRow>
  );
};

export default RelatedKeywordsInput;
