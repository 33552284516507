import React from "react";
import CoreTextField from "../../core/CoreTextField";
import LabeledFormRow from "./LabeledFormRow";

interface Props {
  tone: string;
  onToneChange: (tone: string) => void;
  loading?: boolean;
  required?: boolean;
}

const ToneInput: React.FC<Props> = ({ tone, onToneChange, loading = false, required = false }) => {
  return (
    <LabeledFormRow
      label="Specify the mood or style for the article. It can be friendly, informative, humorous, etc."
      required={required}
    >
      <CoreTextField
        onChange={(value) => {
          onToneChange(value);
        }}
        value={tone}
        label="Desired Writing Tone"
        minRows={1}
        placeholder="Informative, friendly"
        loading={loading}
        required={required}
      />
    </LabeledFormRow>
  );
};

export default ToneInput;
