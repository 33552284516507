import { theme } from "../../styles/theme";
import React, { ReactNode } from "react";
import { Card, CardContent } from "@mui/material";

interface Props {
  children: ReactNode;
  padding?: number | number[];
  backgroundColor?: string;
}

const CoreCard: React.FC<Props> = ({
  children,
  padding = [2, 2, 4, 4],
  backgroundColor = theme.palette.background.paper,
}) => {
  return (
    <Card
      sx={{
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        borderRadius: 5,
        backgroundColor,
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "visible",
      }}
    >
      <CardContent
        sx={{
          p: padding,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          "&:last-child": {
            paddingBottom: padding,
          },
          position: "relative",
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default CoreCard;
