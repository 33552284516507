import React from "react";
import { Chip, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import CoreTextField from "../../core/CoreTextField";
import Image from "next/image";
import CurvedArrowSecondary from "../../../../public/curved-arrow-secondary.svg";
import LabeledFormRow from "./LabeledFormRow";

interface Props {
  keywords: string;
  onKeywordsChange: (keywords: string) => void;
  loading?: boolean;
  required?: boolean;
}

const TargetKeywordsInput: React.FC<Props> = ({
  keywords,
  onKeywordsChange,
  loading = false,
  required = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack direction="row" width="100%">
      <LabeledFormRow
        label="Comma separated list of search keywords the article should rank for"
        required={required}
      >
        <CoreTextField
          label="Target Keyword(s)"
          value={keywords}
          multiline
          onChange={(value) => {
            onKeywordsChange(value);
          }}
          placeholder="monkeys, bananas"
          loading={loading}
          required={required}
        />
      </LabeledFormRow>
      {isMobile === false && (
        <Stack
          spacing={1}
          sx={{
            position: "absolute",
            right: ["-65px", "-65px", "-65px", "-22px"],
            transform: "translateY(-50%) rotate(-5deg)", // added rotation here
          }}
        >
          <Chip
            label={
              <Typography variant="body2" fontFamily="Open Sans Semi Bold">
                <Typography variant="body2" fontFamily="Open Sans Bold" component="span">
                  Rank
                </Typography>{" "}
                For Your Keywords!
              </Typography>
            }
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              transition: "transform 0.3s ease-in-out", // smooth transition for the scaling effect
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                transform: "scale(1.1)", // scale the chip up by 10% on hover
                cursor: "pointer",
              },
            }}
          />
          <Image src={CurvedArrowSecondary} height={30} width={30} alt="Arrow" />
        </Stack>
      )}
    </Stack>
  );
};

export default TargetKeywordsInput;
