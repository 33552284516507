import React, { ReactNode, useEffect, useState } from "react";
import { IAIArticle } from "../../types/models/article.types";
import { Stack } from "@mui/material";
import ToneInput from "./inputs/ToneInput";
import TranslateInput from "./inputs/TranslateInput";
import TopicInput from "./inputs/TopicInput";
import CoreTextField from "../core/CoreTextField";
import LabeledFormRow from "./inputs/LabeledFormRow";
import LengthInput from "./inputs/LengthInput";
import { useRouter } from "next/router";
import { getPersonalizeAIHref, getSignUpHref } from "../../const/Hrefs";
import StartWritingButton from "./inputs/StartWritingButton";

interface Props {
  article: Partial<IAIArticle>;
  onArticleChange: (article: Partial<IAIArticle>) => any;
  isSignedIn: boolean;
  children: ReactNode;
}

const CreateGuestPostForm: React.FC<Props> = ({
  article,
  onArticleChange,
  isSignedIn,
  children,
}) => {
  const router = useRouter();
  const [showAll, setShowAll] = useState<boolean>(false);

  useEffect(() => {
    if (isSignedIn || article?.id) {
      setShowAll(isSignedIn);
    }
  }, [article?.id, isSignedIn]);

  const handleStart = async () => {
    if (!article?.topic && !article?.promotional_url && !isSignedIn) {
      await router.push(getSignUpHref({ redirect_url: getPersonalizeAIHref() }));
    } else {
      setShowAll(true);
    }
  };

  return (
    <>
      <Stack spacing={4} alignItems="center" width="100%%">
        <Stack spacing={2} width="100%">
          <TopicInput
            topic={article?.topic}
            onTopicChange={(topic) => onArticleChange({ topic })}
            required
          />
          <LabeledFormRow
            label="Provide the URL you want to feature in the article. This link will be woven into the content to draw readers to your site.."
            required={true}
          >
            <CoreTextField
              label="Promotional URL"
              onChange={(promotionalUrl) => onArticleChange({ promotional_url: promotionalUrl })}
              value={article?.promotional_url}
              placeholder="https://example.com"
              required={true}
            />
          </LabeledFormRow>
          <LabeledFormRow
            label="Enter keywords as comma-separated values to use as anchor texts for your promotional URL"
            required={true}
          >
            <CoreTextField
              label="Promotional Link Keywords"
              onChange={(keywords) => onArticleChange({ keywords })}
              value={article?.keywords}
              placeholder="monkeys, bananas"
              required={true}
              multiline
            />
          </LabeledFormRow>
          {showAll === false && <StartWritingButton onStartWriting={handleStart} />}
          {showAll === true && (
            <>
              <ToneInput tone={article?.tone} onToneChange={(tone) => onArticleChange({ tone })} />
              <LengthInput
                length={article?.length}
                onLengthChange={(length) => onArticleChange({ length })}
              />
              <TranslateInput
                translate={article?.translate}
                country={article?.country}
                onTranslateChange={(translate) => onArticleChange({ translate })}
                onCountryChange={(country) => onArticleChange({ country })}
              />
              {children}
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default CreateGuestPostForm;
