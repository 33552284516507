import { fetcherPOST, fetcherStream, fetcherStreamJSON } from "../../infra/api";
import { IAIArticle, IArticleSuggestion } from "@/types/models/article.types";
import { IArticleSuggestionsResponse } from "../../types/api/endpoints/IArticleSuggestionsResponse";
import { parseIfJSON } from "../../utils/StringUtils";
import { IArticleResponse } from "../../types/api/endpoints/IArticleResponse";
import { TaskState } from "../../types/task.types";

async function suggestProductArticles(data: {
  user_id: string;
  product_id?: string;
}): Promise<IArticleSuggestion[] | undefined> {
  const response = await fetcherStream(
    "/api/stream/seo/product-to-articles",
    "POST",
    () => undefined,
    data
  );
  return parseIfJSON(response);
}

async function suggestCoverImage(
  onChunk: (chunk: string, isComplete: boolean) => void,
  data: {
    article_id: string;
    content: string;
  }
): Promise<string> {
  return fetcherStream("/api/stream/seo/suggest-cover-image", "POST", onChunk, data);
}

async function writeArticle(
  articleId: string,
  userId: string,
  channelName: string
): Promise<TaskState> {
  return fetcherPOST<TaskState>(`/api/articles/${articleId}/write`, {
    user_id: userId,
    channel_name: channelName,
  });
}

async function suggestArticlesFromTopic(
  topic: string,
  articleId?: string
): Promise<IArticleSuggestionsResponse> {
  return fetcherPOST<IArticleSuggestionsResponse>("/api/seo/suggest-articles-from-topic", {
    topic,
    article_id: articleId,
  });
}

async function generateLsiKeywords(
  onChunk: (chunk: string, isComplete: boolean) => void,
  articleId: string,
  keywords: string,
  title?: string
) {
  return fetcherStream("/api/stream/research/find-lsi", "POST", onChunk, {
    article_id: articleId,
    keywords,
    title,
  });
}

async function autofillCreateForm(
  onChunk: (jsonObj: Partial<IAIArticle>, isComplete: boolean) => void,
  topic: string,
  keywords: string
) {
  return fetcherStreamJSON<Partial<IAIArticle>>(
    "/api/stream/research/autofill-create-form",
    "POST",
    onChunk,
    {
      topic,
      keywords,
    }
  );
}

async function reviseArticle(url?: string, articleContent?: string): Promise<IArticleResponse> {
  return fetcherPOST<IArticleResponse>(`/api/seo/revise-article`, {
    url,
    article_content: articleContent,
  });
}

export default {
  suggestArticlesFromTopic,
  suggestProductArticles,
  suggestCoverImage,
  writeArticle,
  generateLsiKeywords,
  autofillCreateForm,
  reviseArticle,
};
