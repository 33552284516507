import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CoreTextField from "./CoreTextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CoreLoadingButton from "./CoreLoadingButton";
import AddIcon from "@mui/icons-material/Add";
import { Stack } from "@mui/material";

interface Props {
  onChange: (fields: string[]) => void;
  defaultValues?: string[] | undefined;
  label: string;
  placeholder?: string;
  addMoreButtonText?: string;
}

const CoreAddMoreTextFields: React.FC<Props> = ({
  onChange,
  defaultValues,
  label,
  placeholder,
  addMoreButtonText = "Add More",
}) => {
  const [textFields, setTextFields] = useState<string[]>(
    defaultValues?.length ? defaultValues : [""]
  );

  useEffect(() => {
    if (defaultValues) {
      setTextFields(defaultValues);
    }
  }, [defaultValues]);

  const onTextChange = (values: string[]) => {
    onChange(values);
  };

  const handleAddTextField = () => {
    const newFields = [...textFields, ""];
    setTextFields(newFields);
  };

  const handleInputChange = (index: number, value: string) => {
    const values = [...textFields];
    values[index] = value;
    setTextFields(values);
    onTextChange(values);
  };

  const handleRemoveTextField = (index: number) => {
    const values = [...textFields];
    values.splice(index, 1);
    setTextFields(values);
    onTextChange(values);
  };

  return (
    <Box width="100%">
      <Stack spacing={2}>
        {textFields.map((textField, index) => (
          <Box key={index} display="flex" alignItems="center">
            <CoreTextField
              label={`${label} ${index + 1}`}
              value={textField}
              onChange={(value) => handleInputChange(index, value)}
              placeholder={placeholder}
            />
            <IconButton
              onClick={() => handleRemoveTextField(index)}
              color="error"
              disabled={textFields.length === 1} // Disable the button if only one row is present
            >
              <ClearIcon />
            </IconButton>
          </Box>
        ))}
      </Stack>
      <CoreLoadingButton
        text={addMoreButtonText}
        onClick={handleAddTextField}
        startIcon={<AddIcon />}
        size="medium"
        variant="text"
      />
    </Box>
  );
};

export default CoreAddMoreTextFields;
