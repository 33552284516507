import { ISuccessApiResponse } from "../../types/api/endpoints/ISuccessApiResponse";
import { fetcherGET, fetcherPOST } from "../../infra/api";
import { IUserProduct } from "../../types/models/user.types";
import { IUserProductResponse } from "../../types/api/endpoints/IUserProductResponse";
import { IHookResponse } from "../../types/api/core/IHookResponse";
import { IUserAllProductsResponse } from "../../types/api/endpoints/IUserAllProductsResponse";
import { IApiError } from "../../types/api/core/IApiError";
import useSWR from "swr";
import { IUserProductResearchResponse } from "../../types/api/endpoints/IUserProductResearchResponse";

export const useGetAllUserProducts = (
  userId: string
): IHookResponse<IUserAllProductsResponse, IApiError> => {
  const shouldFetch = () => {
    if (!userId) {
      return null;
    }

    return `/api/users/${userId}/product`;
  };

  const { data, error, mutate, isValidating } = useSWR<IUserAllProductsResponse>(
    shouldFetch,
    fetcherGET
  );

  if (data?.error || error) {
    return { error: data?.error || error };
  }

  return { data, mutate, isValidating };
};

export const useGetUserProduct = (
  productId: string
): IHookResponse<IUserProductResponse, IApiError> => {
  const shouldFetch = () => {
    if (!productId) {
      return null;
    }

    return `/api/products/${productId}`;
  };

  const { data, error, mutate, isValidating } = useSWR<IUserProductResponse>(
    shouldFetch,
    fetcherGET
  );

  if (data?.error || error) {
    return { error: data?.error || error };
  }

  return { data, mutate, isValidating };
};

async function deleteUserProduct(productId: string): Promise<ISuccessApiResponse> {
  return fetcherPOST<ISuccessApiResponse>(`/api/products/${productId}/delete`);
}

async function setUserProduct(
  userId: string,
  product: Partial<IUserProduct>
): Promise<IUserProductResponse> {
  return fetcherPOST<IUserProductResponse>(`/api/users/${userId}/product/set`, {
    product,
  });
}

async function researchUserProduct(url: string): Promise<IUserProductResearchResponse> {
  return fetcherPOST<IUserProductResearchResponse>(`/api/products/research-product`, {
    url,
  });
}

async function researchUserProductBlogs(
  sitemap: { url: string; urls_must_contain?: string; urls_must_not_contain?: string },
  userId,
  productId: string
): Promise<ISuccessApiResponse> {
  return fetcherPOST<ISuccessApiResponse>(`/api/products/research-product-blogs`, {
    sitemap,
    user_id: userId,
    product_id: productId,
  });
}

async function dispatchArticleSiteMapScan(userId: string): Promise<ISuccessApiResponse> {
  return fetcherPOST<ISuccessApiResponse>(`/api/products/dispatch-multiple-sitemap-scans`, {
    user_id: userId,
  });
}

export const ProductAPI = {
  dispatchArticleSiteMapScan,
  deleteUserProduct,
  setUserProduct,
  researchUserProduct,
  researchUserProductBlogs,
};
