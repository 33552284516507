import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import LabeledFormRow from "./LabeledFormRow";

interface Props {
  length?: "short" | "long";
  onLengthChange: (length: "short" | "long") => void;
  required?: boolean;
}

const LengthInput: React.FC<Props> = ({ length = "short", onLengthChange, required = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <LabeledFormRow label="Length of article" required={required}>
      <ToggleButtonGroup
        fullWidth
        value={length}
        exclusive
        onChange={(e, value) => {
          if (!value) {
            return null;
          }
          onLengthChange(value);
        }}
        sx={{
          boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        <ToggleButton
          value="short"
          sx={{
            textTransform: "none",
            "&.Mui-selected": { fontFamily: "Open Sans Semi Bold" },
          }}
        >
          <Typography variant={isMobile ? "body2" : "body1"} fontFamily="inherit">
            Normal (800 - 1500 words)
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="long"
          sx={{
            textTransform: "none",
            "&.Mui-selected": { fontFamily: "Open Sans Semi Bold" },
          }}
        >
          <Typography variant={isMobile ? "body2" : "body1"} fontFamily="inherit">
            Long (1500 - 2000 words)
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </LabeledFormRow>
  );
};

export default LengthInput;
