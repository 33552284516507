import React, { ReactNode } from "react";
import { Stack } from "@mui/material";
import { InfoBadge } from "../../InfoBadge";

interface Props {
  children: ReactNode;
  label: string;
  required?: boolean;
}

const LabeledFormRow: React.FC<Props> = ({ children, label, required = false }) => {
  return (
    <Stack direction="row" width="100%">
      <InfoBadge
        title={`${required ? "Required" : "Optional"} - ${label}`}
        color={required === true ? "secondary" : "primary"}
      />
      {children}
    </Stack>
  );
};

export default LabeledFormRow;
