import { alpha, CircularProgress, styled, TextField, Typography } from "@mui/material";
import React from "react";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    overflow: "hidden",
    borderRadius: 6,
    backgroundColor: "white",
    border: "1px solid",
    borderColor: "#E0E3E7",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "&.MuiFilledInput-root.Mui-disabled": {
      backgroundColor: "transparent",
    },
    "&.MuiFormLabel-root-MuiInputLabel-root": {
      fontSize: "16px",
    },
  },
}));

interface Props {
  onChange: (value: any) => void;
  label?: string;
  value?: string | number | undefined;
  defaultValue?: string;
  disabled?: boolean;
  readOnly?: boolean;
  type?: "number" | "text" | "password" | "search";
  placeholder?: string | undefined;
  maxCharacters?: number;
  minRows?: number;
  maxRows?: number;
  multiline?: boolean;
  sx?: {};
  loading?: boolean;
  required?: boolean;
  onKeyPress?: (e: any) => void | undefined;
}

const CoreTextField: React.FC<Props> = ({
  onChange,
  label = undefined,
  value = "",
  placeholder = undefined,
  maxCharacters = 1000,
  defaultValue = undefined,
  disabled = false,
  readOnly = false,
  type = "text",
  minRows = 1,
  maxRows = 0,
  multiline = false,
  sx = {},
  loading = false,
  required = false,
  onKeyPress = undefined,
}) => {
  const isMultiline = multiline || (minRows || 1) > 1;
  if (type && type !== "text" && isMultiline) {
    throw new Error("Multiline can only be of type text");
  }

  return (
    <StyledTextField
      size="medium"
      variant="filled"
      fullWidth
      inputProps={{
        maxLength: maxCharacters,
        readOnly: readOnly,
      }}
      multiline={isMultiline}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      defaultValue={defaultValue}
      disabled={loading || disabled}
      minRows={minRows}
      maxRows={maxRows}
      placeholder={placeholder}
      helperText={
        maxCharacters && typeof value !== "number" && value?.length === maxCharacters ?
          `Max ${maxCharacters} characters` :
          null
      }
      type={type}
      label={
        <Typography fontFamily="Open Sans Semi Bold">
          {label} {required && "*"}
        </Typography>
      }
      sx={{ ...sx }}
      InputProps={{
        disableUnderline: true,
        endAdornment: loading && <CircularProgress size={20} />,
      }}
      InputLabelProps={{ shrink: true }}
      onKeyPress={onKeyPress}
      data-clarity-unmask="true"
    />
  );
};

export default CoreTextField;
