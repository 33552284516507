import { Alert, Snackbar } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";

export interface IToast {
  type: "error" | "success" | "warning" | "info";
  message: string;
  action?: ReactNode;
}

interface Props {
  toast?: IToast | undefined;
  open: boolean;
  onClose?: () => void;
}

const CoreSnackbar: React.FC<Props> = ({
  toast = {
    message: "Oops! Something went wrong, please try again.",
    type: "error",
  },
  open,
  onClose,
}) => {
  const { message, type, action } = toast;
  const [openInternal, setOpenInternal] = useState<boolean>(false);
  const handleClose = (_event?: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenInternal(false);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setOpenInternal(open);
  }, [open, message]);

  // snackbar needs to have child div or else it breaks
  return (
    <Snackbar open={openInternal} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type} action={action}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CoreSnackbar;
