import React from "react";
import CoreSelect from "../../core/CoreSelect";
import { Country, friendlyCountry, Language } from "../../../types/Country";
import LabeledFormRow from "./LabeledFormRow";

interface Props {
  translate?: Language;
  country?: Country;
  onTranslateChange: (translate) => void;
  onCountryChange: (country) => void;
  required?: boolean;
}

const TranslateInput: React.FC<Props> = ({
  translate = Language.English,
  country = Country.UnitedStates,
  onTranslateChange,
  onCountryChange,
  required = false,
}) => {
  return (
    <>
      <LabeledFormRow
        label="Get the final article translated to any language and focus citations"
        required={required}
      >
        <CoreSelect
          label="Article Language"
          value={translate}
          onChange={(t) => onTranslateChange(t as Language)}
          selectItems={Object.keys(Language).map((l) => ({
            label: l,
            value: Language[l],
          }))}
        />
        <CoreSelect
          label="Article Country"
          value={country || "US"}
          onChange={(c) => onCountryChange(c as Country)}
          selectItems={Object.keys(Country).map((c) => ({
            label: friendlyCountry(c as Country),
            value: Country[c],
          }))}
        />
      </LabeledFormRow>
    </>
  );
};

export default TranslateInput;
