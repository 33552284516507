import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import CoreLoadingButton from "../core/CoreLoadingButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useRouter } from "next/router";
import { ARTICLE_LIMIT } from "../../const/Pricing";

const DefaultContent = () => {
  return (
    <>
      <Typography gutterBottom>
        You&apos;ve hit your {ARTICLE_LIMIT}-articles limit this month. Unlock endless articles and
        premium features with our Pro Plan&apos;s <b>7-day free trial</b>.
      </Typography>
      <Typography>
        Don&apos;t worry, your current articles aren&apos;t going anywhere. Access them for free
        next month, or upgrade for instant unlimited access.
      </Typography>
    </>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  onCheckout: () => void;
  title?: string;
  buttonText?: string;
  children?: ReactNode;
}

const PaymentRequiredModal: React.FC<Props> = ({
  open,
  onClose,
  onCheckout,
  title = "Upgrade For Full Access",
  buttonText = "Start Free Trial",
  children = <DefaultContent />,
}) => {
  const theme = useTheme();
  const router = useRouter();
  const [openInternal, setOpenInternal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const handleClose = (_event?: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    onClose();
    setOpenInternal(false);
  };

  const handleSeePlans = () => router.push("/pricing");

  const handleCheckout = async () => {
    setLoading(true);
    try {
      await onCheckout();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setOpenInternal(open);
  }, [open]);

  return (
    <Dialog onClose={handleClose} open={openInternal}>
      <DialogTitle sx={{ padding: 3 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar
            sx={{
              bgcolor: theme.palette.primary.main,
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h6" fontFamily="Open Sans Semi Bold" color="primary">
            {title}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: 3 }}>{children}</DialogContent>
      <DialogActions sx={{ backgroundColor: theme.palette.grey[100], padding: 2 }}>
        <CoreLoadingButton onClick={handleClose} text="Close" variant="text" />
        <CoreLoadingButton onClick={handleSeePlans} text="See Plans" variant="text" />
        <CoreLoadingButton
          onClick={handleCheckout}
          text={buttonText}
          color="secondary"
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PaymentRequiredModal;
