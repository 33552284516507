import React from "react";
import CoreTextField from "../../core/CoreTextField";
import LabeledFormRow from "./LabeledFormRow";

interface Props {
  topic: string;
  onTopicChange: (value: string) => void;
  loading?: boolean;
  required?: boolean;
}

const TopicInput: React.FC<Props> = ({
  topic,
  onTopicChange,
  loading = false,
  required = false,
}) => {
  return (
    <LabeledFormRow label="Topic you want an article about" required={required}>
      <CoreTextField
        onChange={onTopicChange}
        value={topic}
        label="Topic"
        placeholder="Best bananas to feed monkeys"
        loading={loading}
        required={required}
      />
    </LabeledFormRow>
  );
};

export default TopicInput;
