import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";

interface Props {
  title: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  color?: "primary" | "secondary" | "inherit";
}

export function InfoBadge({ title, icon, onClick, color = "primary" }: Props) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip title={title} open={open} disableHoverListener color="primary">
      <IconButton
        color={color}
        onClick={() => {
          setOpen(!open);
          onClick && onClick();
        }}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
      >
        {icon ? icon : <InfoIcon />}
      </IconButton>
    </Tooltip>
  );
}
