import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import CoreTextField from "../core/CoreTextField";
import React, { ReactNode, useEffect, useState } from "react";
import { IAIArticle } from "../../types/models/article.types";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CoreAddMoreTextFields from "../core/CoreAddMoreTextFields";
import LengthInput from "./inputs/LengthInput";
import TranslateInput from "./inputs/TranslateInput";
import TargetKeywordsInput from "./inputs/TargetKeywordsInput";
import ToneInput from "./inputs/ToneInput";
import RelatedKeywordsInput from "./inputs/RelatedKeywordsInput";
import TopicInput from "./inputs/TopicInput";
import LabeledFormRow from "./inputs/LabeledFormRow";
import PersonalizedAiInput from "./inputs/PersonalizedAiInput";
import { IUserProduct, ProductSitemapScanStatus } from "../../types/models/user.types";
import CoreSnackbar, { IToast } from "../core/CoreSnackBar";
import CoreLoadingButton from "../core/CoreLoadingButton";
import SeoAPI from "../../client/api/SeoAPI";
import { getPersonalizeAIHref, getSignUpHref } from "../../const/Hrefs";
import { useRouter } from "next/router";
import StartWritingButton from "./inputs/StartWritingButton";

interface Props {
  article: Partial<IAIArticle>;
  onArticleChange: (article: Partial<IAIArticle>) => any;
  products: Array<{
    product: IUserProduct;
    status: ProductSitemapScanStatus;
  }>;
  isSignedIn: boolean;
  children: ReactNode;
}

const CreateReviseArticleForm: React.FC<Props> = ({
  article,
  onArticleChange,
  products,
  isSignedIn,
  children,
}) => {
  const router = useRouter();
  const [toast, setToast] = useState<IToast>();
  const [reviseLoading, setReviseLoading] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [useArticleUrl, setUseArticleUrl] = useState<boolean>(true);

  useEffect(() => {
    if (showAll === false && article?.topic != null && article?.article_rewrite != null) {
      setShowAll(true);
    }
  }, [article?.topic, article?.article_rewrite]);

  const handleRevise = async () => {
    setToast(undefined);

    if (!article?.article_rewrite) {
      setToast({ message: "Article URL or content required", type: "error" });
      return;
    }

    setReviseLoading(true);
    setShowAll(true);

    try {
      let url = undefined;
      let articleContent = undefined;
      if (useArticleUrl) {
        url = article?.article_rewrite;
      } else {
        articleContent = article?.article_rewrite;
      }
      const data = await SeoAPI.reviseArticle(url, articleContent);
      await onArticleChange(data.article);
    } catch (e) {
      console.error(e);
      setToast({
        type: "error",
        message: "Unknown issue occurred, please try again.",
      });
    } finally {
      setReviseLoading(false);
    }
  };

  const handleStart = async () => {
    if (!article?.article_rewrite && !isSignedIn) {
      await router.push(getSignUpHref({ redirect_url: getPersonalizeAIHref() }));
      return;
    }
    await handleRevise();
  };

  return (
    <>
      <Stack spacing={4} alignItems="center" width="100%%">
        <Stack spacing={2} width="100%" alignItems="center">
          {useArticleUrl === false && (
            <LabeledFormRow label="Enter your article content you want to rewrite" required>
              <CoreTextField
                value={article?.article_rewrite}
                onChange={(articleRewrite) => onArticleChange({ article_rewrite: articleRewrite })}
                label="Article Content*"
                minRows={3}
                placeholder="Baboons, known for their complex social structures, play a crucial role in African savannah ecosystems"
                maxCharacters={200000}
                loading={reviseLoading}
              />
            </LabeledFormRow>
          )}
          {useArticleUrl === true && (
            <LabeledFormRow label="Enter your article url you want to rewrite" required>
              <CoreTextField
                label="Article URL*"
                value={article?.article_rewrite}
                onChange={(articleRewrite) => onArticleChange({ article_rewrite: articleRewrite })}
                placeholder="https://example.com/best-bananas-to-feed-monkeys"
                loading={reviseLoading}
              />
            </LabeledFormRow>
          )}
          <Stack direction="row" alignItems="center">
            <Typography
              variant="body1"
              fontFamily={!useArticleUrl ? "Open Sans Semi Bold" : "inherit"}
              color="primary"
            >
              Use Article Content
            </Typography>
            <Switch
              checked={useArticleUrl}
              color="default"
              onChange={(e) => {
                setUseArticleUrl(e.target.checked);
              }}
            />
            <Typography
              variant="body1"
              fontFamily={useArticleUrl ? "Open Sans Semi Bold" : "inherit"}
              color="primary"
            >
              Use Article URL
            </Typography>
          </Stack>
        </Stack>
        {showAll === false && !isSignedIn ? (
          <StartWritingButton onStartWriting={handleStart} />
        ) : (
          <CoreLoadingButton
            text=" ✨ Rewrite Article ✨"
            onClick={handleRevise}
            loading={reviseLoading}
          />
        )}
        {showAll === true && (
          <>
            <Stack spacing={2} width="100%">
              <TopicInput
                topic={article?.topic}
                onTopicChange={(topic) => onArticleChange({ topic })}
                required
                loading={reviseLoading}
              />
              <TargetKeywordsInput
                keywords={article?.keywords}
                onKeywordsChange={(keywords) => onArticleChange({ keywords })}
                loading={reviseLoading}
              />
              <RelatedKeywordsInput
                relatedKeywords={article?.related_keywords}
                onRelatedKeywordsChange={(relatedKeywords) =>
                  onArticleChange({ related_keywords: relatedKeywords })
                }
                loading={reviseLoading}
              />
              <LabeledFormRow label="Specific facts or points you'd like to ensure are mentioned in the article.">
                <CoreTextField
                  onChange={(factsToInclude) =>
                    onArticleChange({ facts_to_include: factsToInclude })
                  }
                  value={article?.facts_to_include}
                  label="Key Facts to Highlight"
                  minRows={3}
                  placeholder="Baboons peel bananas and prefer the sweet inner fruit, just like humans."
                  loading={reviseLoading}
                />
              </LabeledFormRow>
              <ToneInput
                tone={article?.tone}
                onToneChange={(tone) => onArticleChange({ tone })}
                loading={reviseLoading}
              />
              <PersonalizedAiInput
                products={products}
                productId={article?.product_id}
                onProductChange={(productValue) => {
                  onArticleChange({
                    product_id: productValue?.id,
                    tone: productValue?.tone,
                  });
                }}
                loading={reviseLoading}
              />
              <LengthInput
                length={article?.length}
                onLengthChange={(length) => onArticleChange({ length })}
              />
              <TranslateInput
                translate={article?.translate}
                country={article?.country}
                onTranslateChange={(translate) => onArticleChange({ translate })}
                onCountryChange={(country) => onArticleChange({ country })}
              />
              <LabeledFormRow label="Links or citations to include in the article.">
                <CoreAddMoreTextFields
                  onChange={(linksToInclude) =>
                    onArticleChange({ links_to_include: linksToInclude })
                  }
                  defaultValues={article?.links_to_include}
                  label="Link To Include"
                  placeholder="https://source.com/"
                  addMoreButtonText="Add More Links"
                />
              </LabeledFormRow>
              <Stack alignItems="center">
                <FormControlLabel
                  control={<Switch defaultChecked color="primary" size="medium" />}
                  label={
                    <Stack sx={{ ml: 1 }} alignItems="center" margin="auto" width="100%">
                      <Stack direction="row">
                        <TravelExploreIcon />
                        <Typography fontFamily="Open Sans Bold">
                          Include Realtime Google Search Results
                        </Typography>
                      </Stack>
                      <Typography>
                        Use live Google search to research the article (not limited to 2021)
                      </Typography>
                    </Stack>
                  }
                />
              </Stack>
            </Stack>
            {children}
          </>
        )}
      </Stack>
      <CoreSnackbar toast={toast} open={!!toast} />
    </>
  );
};

export default CreateReviseArticleForm;
