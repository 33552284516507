import { SubscriptionFrequency, SubscriptionType } from "../SubscriptionType";

export interface IUser {
  id: string;
  name?: string;
  created_at: string;
  updated_at: string;
  email?: string;
  provider?: string;
  access_token?: string;
  last_action?: Date;
  stripe_customer_id?: string;
  subscription_id?: string;
  subscription?: SubscriptionType;
  gpt4_subscription?: boolean;
  fbclid?: string;
  gclid?: string;
  internal?: boolean;
  ipaddr?: string;
  free_trial_started_at?: Date;
  plan_frequency?: SubscriptionFrequency;
  reditus_id?: string;
}

export interface IUserProductBlog {
  id?: string;
  product_id?: string;
  text: string;
  url: string;
  lastmod?: number;
}

export enum ProductSitemapScanStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed",
}

export interface IUserProduct {
  id: string;
  user_id: string;
  name: string;
  description: string;
  summary: string;
  website: string;
  contact: string;
  competitors: string[];
  tone: string;
  created_at: string;
  updated_at: string;
  sitemap: string;
  article_regex: string;
  defer_execution_id: string;
  sitemap_scanned_at: string;
  internal_links: string[];
}
