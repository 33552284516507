export enum SubscriptionType {
  ENTERPRISE = "enterprise",
  EXPERT = "expert",
  PRO = "pro",
  GPT4 = "gpt4",
  MANAGED_SEO = "managed_seo",
}

export enum SubscriptionFrequency {
  MONTH = "month",
  YEAR = "year",
  WEEK = "week",
  DAY = "day",
}

export function toFriendlySubscriptionName(subscription: SubscriptionType): string {
  if (subscription === SubscriptionType.EXPERT) {
    return "Expert";
  } else if (subscription === SubscriptionType.PRO) {
    return "Pro";
  }
  return subscription;
}
