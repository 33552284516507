import {
  Box,
  Chip,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: 0.5,
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: "6px",
    },
    "&:first-of-type": {
      borderRadius: "6px",
    },
  },
  backgroundColor: theme.palette.grey[100],
  padding: "4px",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return {
    "&.Mui-selected": {
      backgroundColor: "white",
      fontFamily: "Open Sans Semi Bold",
    },
    paddingLeft: isMobile ? "16px" : "48px",
    paddingRight: isMobile ? "16px" : "48px",
    textTransform: "none",
    flexWrap: "wrap",
  };
});

export interface ButtonToggleItem {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  label: string;
  value: string;
  color: "primary" | "secondary";
  chip?: {
    label: string;
    color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  };
}

interface Props {
  value: string;
  handleValueChange: (value: any) => void;
  buttonToggleItems: ButtonToggleItem[];
}

const ButtonToggleGroup: React.FC<Props> = ({ value, handleValueChange, buttonToggleItems }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
        maxWidth: "100%",
        overflowX: "auto",
        borderRadius: "6px",
      }}
    >
      <StyledToggleButtonGroup
        exclusive
        value={value}
        onChange={(e, v) => {
          if (!v) {
            return;
          }
          handleValueChange(v);
        }}
      >
        {buttonToggleItems.map((item) => (
          <StyledToggleButton key={item.value} value={item.value} color={item.color}>
            {item.startIcon && (
              <Typography
                fontFamily="inherit"
                variant={isMobile ? "body2" : "body1"}
                sx={{ mr: 1, alignItems: "center", display: "flex" }}
              >
                {item.startIcon}
              </Typography>
            )}
            <Typography fontFamily="inherit" variant={isMobile ? "body2" : "body1"}>
              {item.label}
            </Typography>
            {item?.endIcon && (
              <Typography
                fontFamily="inherit"
                variant={isMobile ? "body2" : "body1"}
                sx={{ ml: 1, alignItems: "center", display: "flex" }}
              >
                {item.endIcon}
              </Typography>
            )}
            {item.chip && (
              <Chip
                label={
                  <Typography variant="body2" fontFamily="Open Sans Semi Bold">
                    {item.chip.label}
                  </Typography>
                }
                color={item.chip.color}
                size="small"
                sx={{ ml: 1, mt: [0, 0, 1, 0], position: "relative", top: "-3px" }}
              />
            )}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Box>
  );
};

export default ButtonToggleGroup;
