import React from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";

type SocialContextProps = {
  images: string[];
  text: string;
};

const Facepile: React.FC<SocialContextProps> = ({ images, text }) => {
  return (
    <Stack
      direction={["column", "column", "row", "row"]}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse", // Stack from right to left
          position: "relative",
        }}
      >
        {images.map((image, index) => (
          <Avatar
            key={index}
            src={image}
            sx={{
              width: 45,
              height: 45,
              marginLeft: -2, // Create overlap except for the first image
              zIndex: images.length - index, // Stack them visually
              borderRadius: "50%",
              border: "2px solid #ffb300", // Adding a white border
            }}
          />
        ))}
      </Box>
      <Typography
        sx={{
          marginLeft: 2,
          color: "rgb(85, 85, 85)",
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default Facepile;
