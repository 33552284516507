import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import CoreLoadingButton from "../../core/CoreLoadingButton";
import Facepile from "../../marketing/Facepile";

interface Props {
  onStartWriting: () => void;
}

const StartWritingButton: React.FC<Props> = ({ onStartWriting }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleStart = async () => {
    setLoading(true);
    try {
      await onStartWriting();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={2}>
      <Box display="flex" justifyContent="center">
        <CoreLoadingButton
          loading={loading}
          variant="contained"
          onClick={handleStart}
          sx={{
            py: [1, 1, 1.5, 1.5],
            px: [2, 2, 4, 4],
            fontSize: "1.2em",
            transition: "transform 0.2s, boxShadow 0.2s, backgroundColor 0.2s",
            "&:hover": {
              transform: "scale(1.05)", // Slight zoom on hover
              boxShadow: "0px 0px 20px 10px rgba(255, 255, 255, 0.9)",
              backgroundColor: "primary.main",
              textShadow: "0px 0px 10px rgba(255, 255, 255, 0.9)"
            }
          }}
          text={
            <Stack>
              <Typography
                variant="h6"
                component="span"
                sx={{
                  fontFamily: "Open Sans Bold"
                }}
              >
                ✨ Start Writing
                <Typography component="span"> — It&apos;s free</Typography>
              </Typography>
            </Stack>
          }
        />
      </Box>
      <Facepile
        images={[
          "https://framerusercontent.com/images/5qJkCzCew4ssOKvMfP7XYjLo4sM.jpg",
          "https://framerusercontent.com/images/P721nJryF0M4besuzTm6EtjuwE.jpg",
          "https://framerusercontent.com/images/2Uq1CKB2tMXoDAZEcHHNfR34PA.jpg"
        ]}
        text="Loved by thousands of marketers"
      />
    </Stack>
  );
};

export default StartWritingButton;
