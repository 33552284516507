import React, { ReactNode } from "react";
import {
  alpha,
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "white",
    border: "1px solid",
    borderColor: "#E0E3E7",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
      backgroundColor: "white",
    },
    "&:focus": {
      backgroundColor: "white",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "&.MuiSelect-select.Mui-disabled": {
      backgroundColor: "white",
    },
  },
}));

export interface SelectItem {
  label: string;
  menu?: ReactNode;
  value: string;
  link?: { href: string; label: string };
}

interface Props {
  label: string;
  value: string;
  onChange: (value: string) => void;
  selectItems: SelectItem[];
  children?: ReactNode;
  required?: boolean;
}

const CoreSelect: React.FC<Props> = ({
  label,
  value,
  onChange,
  selectItems,
  children,
  required = false,
}) => {
  return (
    <FormControl fullWidth variant="filled" size="medium">
      <InputLabel
        id="demo-simple-select-label"
        shrink={!!selectItems?.find((item) => item.value === value)}
      >
        <Typography fontFamily="Open Sans Semi Bold">
          {label} {required && "*"}
        </Typography>
      </InputLabel>
      <StyledSelect
        displayEmpty
        disableUnderline
        value={value}
        onChange={(e) => onChange(e.target.value as string)}
        label={label}
        renderValue={(selectedValue) => {
          const selectedItem = selectItems.find((item) => item.value === selectedValue);
          return <Typography>{selectedItem ? selectedItem.label : ""}</Typography>;
        }}
      >
        {selectItems.map((item) => (
          <MenuItem key={item.value} value={item.value} sx={{ py: 1 }}>
            {item.menu ? item.menu : <Typography>{item.label}</Typography>}
          </MenuItem>
        ))}
        {children && [
          <Divider key="divider" sx={{ mx: 1 }} />,
          <Box key="box" sx={{ px: 2, py: 1 }}>
            {children}
          </Box>,
        ]}
      </StyledSelect>
    </FormControl>
  );
};

export default CoreSelect;
