import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import CoreTextField from "../core/CoreTextField";
import React, { ReactNode, useEffect, useState } from "react";
import { IAIArticle } from "../../types/models/article.types";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CoreAddMoreTextFields from "../core/CoreAddMoreTextFields";
import LengthInput from "./inputs/LengthInput";
import TranslateInput from "./inputs/TranslateInput";
import TargetKeywordsInput from "./inputs/TargetKeywordsInput";
import ToneInput from "./inputs/ToneInput";
import RelatedKeywordsInput from "./inputs/RelatedKeywordsInput";
import TopicInput from "./inputs/TopicInput";
import LabeledFormRow from "./inputs/LabeledFormRow";
import PersonalizedAiInput from "./inputs/PersonalizedAiInput";
import { IUserProduct, ProductSitemapScanStatus } from "../../types/models/user.types";
import CoreSnackbar, { IToast } from "../core/CoreSnackBar";
import SeoAPI from "../../client/api/SeoAPI";
import CoreLoadingButton from "../core/CoreLoadingButton";
import { useRouter } from "next/router";
import { getPersonalizeAIHref, getSignUpHref } from "../../const/Hrefs";
import StartWritingButton from "./inputs/StartWritingButton";

interface Props {
  article: Partial<IAIArticle>;
  onArticleChange: (article: Partial<IAIArticle>) => any;
  products: Array<{
    product: IUserProduct;
    status: ProductSitemapScanStatus;
  }>;
  isSignedIn: boolean;
  children: ReactNode;
}

const CreateArticleForm: React.FC<Props> = ({
  article,
  onArticleChange,
  products,
  isSignedIn,
  children,
}) => {
  const router = useRouter();
  const [toast, setToast] = useState<IToast>();
  const [autofillLoading, setAutofillLoading] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  useEffect(() => {
    setShowAll(isSignedIn || !!article?.id);
  }, [article?.id, isSignedIn]);

  const handleAutoFill = async () => {
    setToast(undefined);

    if (!article?.topic && !article?.keywords) {
      setToast({ message: "Topic or Target Keyword Required", type: "error" });
      return;
    }

    setAutofillLoading(true);
    setShowAll(true);

    const updates: Partial<IAIArticle> = {};
    try {
      await SeoAPI.autofillCreateForm(
        (chunk, isComplete) => {
          updates["related_keywords"] = chunk.related_keywords;
          updates["facts_to_include"] = chunk.facts_to_include;
          updates["length"] = "short";
          updates["tone"] = chunk.tone;
          updates["translate"] = chunk.translate;
          onArticleChange(updates);
        },
        article?.topic || article?.keywords,
        article?.keywords || article?.topic
      );
      await fetch("/api/auth/get-lang").then(async (resp) => {
        if (resp?.ok) {
          const { language: languageResp, country: countryResp } = await resp.json();
          // translate is set to en by default, so if it's en, we want to set it to the language we got back
          updates["translate"] =
            updates["translate"] === "en" ?
              languageResp || updates["translate"] :
              updates["translate"];
          updates["country"] = countryResp;
        }
      });
      onArticleChange(updates);
      setAutofillLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setAutofillLoading(false);
    }
  };

  const handleStart = async () => {
    if (!article?.topic && !article?.keywords && !isSignedIn) {
      await router.push(getSignUpHref({ redirect_url: getPersonalizeAIHref() }));
      return;
    }
    await handleAutoFill();
  };

  return (
    <>
      <Stack spacing={4} alignItems="center" width="100%%">
        <Stack spacing={2} width="100%">
          <TopicInput
            topic={article?.topic}
            onTopicChange={(topic) => onArticleChange({ topic })}
            required={true}
            loading={autofillLoading}
          />
          <TargetKeywordsInput
            keywords={article?.keywords}
            onKeywordsChange={(keywords) => onArticleChange({ keywords })}
            required={true}
            loading={autofillLoading}
          />
        </Stack>
        {showAll === false && <StartWritingButton onStartWriting={handleStart} />}
        {showAll === true && (
          <>
            <CoreLoadingButton
              text=" ✨ Autofill ✨"
              onClick={handleAutoFill}
              loading={autofillLoading}
            />
            <Stack spacing={2} width="100%">
              <RelatedKeywordsInput
                relatedKeywords={article?.related_keywords}
                onRelatedKeywordsChange={(relatedKeywords) =>
                  onArticleChange({ related_keywords: relatedKeywords })
                }
                loading={autofillLoading}
              />
              <LabeledFormRow label="Specific facts or points you'd like to ensure are mentioned in the article.">
                <CoreTextField
                  onChange={(factsToInclude) =>
                    onArticleChange({ facts_to_include: factsToInclude })
                  }
                  value={article?.facts_to_include}
                  label="Key Facts to Highlight"
                  minRows={3}
                  placeholder="Baboons peel bananas and prefer the sweet inner fruit, just like humans."
                  loading={autofillLoading}
                />
              </LabeledFormRow>
              <ToneInput
                tone={article?.tone}
                onToneChange={(tone) => onArticleChange({ tone })}
                loading={autofillLoading}
              />
              <PersonalizedAiInput
                products={products}
                productId={article?.product_id}
                onProductChange={(productValue) => {
                  onArticleChange({
                    product_id: productValue?.id,
                    tone: productValue?.tone,
                  });
                }}
                loading={autofillLoading}
              />
              <LengthInput
                length={article?.length}
                onLengthChange={(length) => onArticleChange({ length })}
              />
              <TranslateInput
                translate={article?.translate}
                country={article?.country}
                onTranslateChange={(translate) => onArticleChange({ translate })}
                onCountryChange={(country) => onArticleChange({ country })}
              />
              <LabeledFormRow label="Links or citations to include in the article.">
                <CoreAddMoreTextFields
                  onChange={(linksToInclude) =>
                    onArticleChange({ links_to_include: linksToInclude })
                  }
                  defaultValues={article?.links_to_include}
                  label="Link To Include"
                  placeholder="https://source.com/"
                  addMoreButtonText="Add More Links"
                />
              </LabeledFormRow>
              <Stack alignItems="center">
                <FormControlLabel
                  control={<Switch defaultChecked color="primary" size="medium" />}
                  label={
                    <Stack sx={{ ml: 1 }} alignItems="center" margin="auto" width="100%">
                      <Stack direction="row">
                        <TravelExploreIcon />
                        <Typography fontFamily="Open Sans Bold">
                          Include Realtime Google Search Results
                        </Typography>
                      </Stack>
                      <Typography>
                        Use live Google search to research the article (not limited to 2021)
                      </Typography>
                    </Stack>
                  }
                />
              </Stack>
            </Stack>
            {children}
          </>
        )}
      </Stack>
      <CoreSnackbar toast={toast} open={!!toast} />
    </>
  );
};

export default CreateArticleForm;
