import { Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import CoreSelect, { SelectItem } from "@/components/core/CoreSelect";
import { getPersonalizeAIEditHref, getPersonalizeAIHref } from "@/const/Hrefs";
import React from "react";
import { IUserProduct, ProductSitemapScanStatus } from "@/types/models/user.types";
import { useRouter } from "next/router";

const getStatusColors = (status: ProductSitemapScanStatus) => {
  const theme = useTheme();
  if (status === ProductSitemapScanStatus.COMPLETED) {
    return theme.palette.success.main;
  }
  if (status === ProductSitemapScanStatus.FAILED) {
    return theme.palette.error.main;
  }
  return theme.palette.grey[500];
};

const getStatusText = (status: ProductSitemapScanStatus) => {
  if (status === ProductSitemapScanStatus.COMPLETED) {
    return "Internal Links Ready";
  }
  if (status === ProductSitemapScanStatus.FAILED) {
    return "Try Again, Internal Links Failed";
  }
  if (status === ProductSitemapScanStatus.IN_PROGRESS) {
    return "Internal Links Scan In Progress";
  }
  return "No Internal Link";
};

interface Props {
  productId?: string | null;
  products: Array<{
    product: IUserProduct;
    status: ProductSitemapScanStatus;
  }>;
  handleSelectProduct: (product: IUserProduct | null) => void;
  showPredraftOption: boolean;
}

export default function SelectAiModelComponent({
  productId = null,
  products,
  handleSelectProduct,
  showPredraftOption,
}: Props) {
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (selectedProductId: string) => {
    const foundProduct = products.find((item) => item.product.id === selectedProductId);
    handleSelectProduct(foundProduct?.product);
  };

  const getOptions = (): SelectItem[] => {
    const options: SelectItem[] =
      products?.map(({ product, status }) => {
        const label = `${product.name || product.website} AI`;
        return {
          label,
          menu: (
            <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
              <Stack direction={["column", "row"]}>
                <Typography sx={{ mr: [0, 0.5] }}>
                  {label} {!isMobile && "-"}
                </Typography>
                <Typography
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  variant="body2"
                  sx={{
                    color: getStatusColors(status),
                  }}
                >
                  {getStatusText(status)}
                </Typography>
              </Stack>

              <Link
                underline="hover"
                onClick={(event) => {
                  event.stopPropagation();
                  router.push(
                    getPersonalizeAIEditHref(product.id, { redirect_url: router.asPath })
                  );
                }}
              >
                <Typography fontFamily="Open Sans Semi Bold" variant="body2">
                  Edit
                </Typography>
              </Link>
            </Stack>
          ),
          value: product.id,
        };
      }) || [];

    if (showPredraftOption) {
      options.unshift({
        label: "Predraft Default AI",
        value: null,
      });
    }

    return options;
  };

  return (
    <CoreSelect
      label="Choose Your AI"
      value={productId}
      onChange={handleChange}
      selectItems={getOptions()}
    >
      <Link
        href={getPersonalizeAIHref({ redirect_url: router.asPath })}
        underline="hover"
        color="#005885"
        sx={{ cursor: "pointer" }}
      >
        <Typography fontFamily="Open Sans Semi Bold" color="#005885">
          Personalize Your Own AI
        </Typography>
      </Link>
    </CoreSelect>
  );
}
