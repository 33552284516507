import React, { ReactNode, useEffect, useState } from "react";
import { IAIArticle } from "../../types/models/article.types";
import { Stack, Typography } from "@mui/material";
import CoreTextField from "../core/CoreTextField";
import TargetKeywordsInput from "./inputs/TargetKeywordsInput";
import RelatedKeywordsInput from "./inputs/RelatedKeywordsInput";
import ToneInput from "./inputs/ToneInput";
import TranslateInput from "./inputs/TranslateInput";
import LengthInput from "./inputs/LengthInput";
import { isAmazonProductLink } from "../../utils/ArticleUtils";
import LabeledFormRow from "./inputs/LabeledFormRow";
import StartWritingButton from "./inputs/StartWritingButton";
import CoreSnackbar, { IToast } from "../core/CoreSnackBar";
import { getPersonalizeAIHref, getSignUpHref } from "../../const/Hrefs";
import { useRouter } from "next/router";

interface Props {
  article: Partial<IAIArticle>;
  onArticleChange: (article: Partial<IAIArticle>) => any;
  isSignedIn: boolean;
  children: ReactNode;
}

const CreateProductReviewForm: React.FC<Props> = ({
  article,
  onArticleChange,
  isSignedIn,
  children,
}) => {
  const router = useRouter();
  const [validUrlMsg, setValidUrlMsg] = useState<string>();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [toast, setToast] = useState<IToast>();

  useEffect(() => {
    if (isSignedIn || article?.id) {
      setShowAll(isSignedIn);
    }
  }, [article?.id, isSignedIn]);

  const handleStart = async () => {
    setToast(undefined);
    if (!article?.amazon_url && !article?.keywords && !isSignedIn) {
      await router.push(getSignUpHref({ redirect_url: getPersonalizeAIHref() }));
    } else if (!article?.amazon_url) {
      setToast({ message: "Amazon URL Required", type: "error" });
    } else {
      setShowAll(true);
    }
  };

  const DISABLE_NEXT = !article?.amazon_url || !isAmazonProductLink(article?.amazon_url);

  useEffect(() => {
    if (!article?.amazon_url) {
      setValidUrlMsg("");
    } else if (isAmazonProductLink(article?.amazon_url)) {
      setValidUrlMsg("Valid Amazon Url ✅");
    } else {
      setValidUrlMsg("Invalid Amazon Url.");
    }
  }, [article?.amazon_url]);

  return (
    <>
      <Stack spacing={4} alignItems="center" width="100%%">
        <Stack spacing={2} width="100%">
          <Stack spacing={1}>
            <LabeledFormRow
              label="Please provide the URL for the Amazon product. Don't use a shortened URL. We support all international Amazon stores."
              required={true}
            >
              <CoreTextField
                onChange={(amazonUrl) => onArticleChange({ amazon_url: amazonUrl })}
                value={article?.amazon_url}
                label="Amazon Product URL"
                placeholder="https://www.amazon.com/dp/B07ZPC9QD4/"
                required={true}
              />
            </LabeledFormRow>
            {validUrlMsg && (
              <Typography align="center" variant="body2" color="textSecondary">
                {validUrlMsg}
              </Typography>
            )}
          </Stack>
          <TargetKeywordsInput
            keywords={article?.keywords}
            onKeywordsChange={(keywords) => onArticleChange({ keywords })}
            required={false}
          />
          {showAll === false && <StartWritingButton onStartWriting={handleStart} />}
          {showAll === true && (
            <>
              <RelatedKeywordsInput
                relatedKeywords={article?.related_keywords}
                onRelatedKeywordsChange={(relatedKeywords) =>
                  onArticleChange({ related_keywords: relatedKeywords })
                }
              />
              <ToneInput tone={article?.tone} onToneChange={(tone) => onArticleChange({ tone })} />
              <LengthInput
                length={article?.length}
                onLengthChange={(length) => onArticleChange({ length })}
              />
              <TranslateInput
                translate={article?.translate}
                country={article?.country}
                onTranslateChange={(translate) => onArticleChange({ translate })}
                onCountryChange={(country) => onArticleChange({ country })}
              />
              {children}
            </>
          )}
        </Stack>
      </Stack>
      <CoreSnackbar toast={toast} open={!!toast} />
    </>
  );
};

export default CreateProductReviewForm;
